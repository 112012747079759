.container {
    color: #fff;
    margin: 2em 2em;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4em;
    color: #fff;
    font-family: var(--font-family);

}

.body {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 0.05em;

}

.body p {
    color: rgb(255, 255, 255);
    font-family: var(--font-family);
    font-weight: 200;
    font-size: 16px;
    line-height: 35px;
    margin: 0 1rem;
    word-spacing: 10px;
}

.body ul{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 200;
    font-size: 24px;
    line-height: 40px;
    margin: 0 1rem;
    word-spacing: 14px;
}

.gpt3__blog-container_groupB {

    flex: 1;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 2rem;

}