.btn {
    background-color: #222;
    color: #fff;
    padding: 0.5em 1em;
    text-decoration: none;
    transition: 0.5s;
}


.btn:hover{
    color: #ffbb33;
}

