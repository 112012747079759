.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row ;
    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1;
    width: 180px;
    margin-right: 2rem;
}

.gpt3__features-container__feature-title h1{
    font-family: var(--font-family);
    font-weight: 800px;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
}

.gpt3__features-container__feature-title div{
 width: 38px;
 height: 3px;
 background: var(--gradient-bar);
 box-shadow: 0 4px 4px rgba(0,0,0,0);
 margin-bottom: 0.25rem;
}

.gpt3__features-container__feature-text {


    flex: 2;
    max-width: 390px;
    display: flex;

}

.gpt3__features-container__feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14;
    line-height: 24px;
    color: #81afdd;
    
}


@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size:14px;
        line-height: 22px;
    }

    .gpt3__features-container__feature-title p {
        font-size:12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
}